import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import './OtpInput.scss';

const OtpInput = ({ value, autofocus, ...props }) => {
  const cls = b.with('otp-input');
  const ref = React.createRef();
  useEffect(() => {
    if (autofocus && ref.current) {
      ref.current.focus();
    }
  }, [ref, autofocus]);

  return (
    <div className={cls({ value: !!value })}>
      <input
        ref={ref}
        className={cls('input')}
        type="text"
        maxLength="6"
        autoComplete="one-time-code"
        value={value}
        {...props}
      />
    </div>
  );
};

OtpInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  autofocus: PropTypes.bool,
};

OtpInput.defaultProps = {
  autofocus: false,
};

export default OtpInput;
