import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dropdown from 'spotii-ui/Dropdown';
import Button from 'spotii-ui/Button';
import RefundComments from 'components/RefundComments';


const RefundActions = ({
  refund,
  onActionSuccess
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleRefundComment = async () => {
    setShow(true);
  };


  return (
    <>
      <Dropdown alignRight>
        <Dropdown.Toggle as={Dropdown.CustomToggle}>
          <Button
            type="transparent"
            shape="square"
            size="small"
            icon={isLoading ? 'loading' : 'ellipsis'}
            disabled={isLoading}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item
              style={{ display: 'flex' }}
              as="button"
              onClick={handleRefundComment}
            >
              {t('changeComments')}
            </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <RefundComments
        refund={refund}
        show={show}
        onClose={() => setShow(false)}
        onSuccess={() => {
          setShow(false);
          if (onActionSuccess) {
            onActionSuccess();
          }
        }}
      />
    </>
  );
};

RefundActions.propTypes = {
  refund: PropTypes.shape({
    transaction_id: PropTypes.string,
    refund_comments: PropTypes.string,
  }),
  onActionSuccess: PropTypes.func,
};

RefundActions.defaultProps = {
  onActionSuccess: null
};

export default RefundActions;
