import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form, { FormRow, FormError, InputRow } from 'spotii-ui/Form';
import { IntlTelInput } from 'spotii-ui/Input';
import Button from 'spotii-ui/Button';
import authApi from 'api/auth';
import { setRegistrationFields } from 'reducers/registration';
import snakeToCamel from 'utils/snakeToCamel';
import sanitizePhone from 'utils/sanitizePhone';

const Personal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isValid, setIsValid] = React.useState(null);
  // const { userId } = useSelector(state => state.application.currentUser || {});
  const { lastName, firstName, phoneNumber, email } = useSelector(state => state.registration);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('atLeastTwoLetters'))
      .matches(/^([^0-9\u0660-\u0669]*)$/, {
        message: t('numbersNotAllowedInName'),
        excludeEmptyString: false,
      })
      .matches(/^([^\u0621-\u064A]*)$/, {
        message: t('englishNamesOnly'),
        excludeEmptyString: false,
      })
      .required(t('firstNameRequired')),
    lastName: Yup.string()
      .min(2, t('atLeastTwoLetters'))
      .matches(/^([^0-9\u0660-\u0669]*)$/, {
        message: t('numbersNotAllowedInName'),
        excludeEmptyString: false,
      })
      .matches(/^([^\u0621-\u064A]*)$/, {
        message: t('englishNamesOnly'),
        excludeEmptyString: false,
      })
      .required(t('lastNameRequired')),
  });
  const processErrors = e => {
    let result = {};
    const { errors } = e;
    const processFields = obj =>
      Object.keys(obj).reduce((res, key) => {
        // Process profile as set of own errors
        if (key === 'profile') {
          return {
            ...res,
            ...processFields(obj[key]),
          };
        }

        res[snakeToCamel(key)] = Array.isArray(obj[key]) ? obj[key][0] : obj[key];
        return res;
      }, {});

    if (errors) {
      result = processFields(errors);

      if (errors.code === 'user_already_exists') {
        result.phoneNumber = t('alreadyHaveAccountWithThisPhoneNumber');
      }
    }

    return result;
  };

  const currLang = useSelector(state => state.language.language);

  const [selectedCountry, setSelectedCountry] = React.useState('qa');
  const handlePhoneNumberChange = num => {
    setFieldValue('phoneNumber', num?.replace(/\s/g, '').replace(/-/g, ''));
  };

  const prepareData = values => {
    const cc = selectedCountry;
    const phone = values.phoneNumber.replace(/^0/, '');
    return {
      ...values,
      phoneNumber: phone,
      legalBusinessName: '',
      businessSupportPhoneNumber: phone,
      businessSupportEmail: email,
      countryId: (cc || '').toUpperCase(),
      businessSector: 'accessories',
      city: 'Qatar',
      ecommercePlatform: 'In-store',
      licenseExpiredAt: '2029-12-31',
      licenseNumber: '123456',
      line1: 'Doha',
      postcode: '123456',
      websiteUrl: 'https://paylaterapp.com/',
      yearlySales: '0',
    };
  };

  const formik = useFormik({
    initialValues: {
      lastName,
      firstName,
      phoneNumber: phoneNumber ? sanitizePhone(phoneNumber) : '',
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      if (!isValid) {
        actions.setErrors({
          phoneNumber: t('phoneNumberFormat'),
        });
        return;
      }
      try {
        const data = prepareData(values);
        await authApi.registrationUpdate(data);
        await authApi.registrationValidate();
        // TODO: We should analyze response from validation ?..
        // Save current fields in the memory. Phone number in international format
        dispatch(
          setRegistrationFields({
            ...values,
            phoneNumber: data.phoneNumber,
            legalBusinessName: data.legalBusinessName,
            businessSupportPhoneNumber: data.businessSupportPhoneNumber,
            businessSupportEmail: email,
            countryId: data.countryId,
          }),
        );
        actions.setStatus('success');
      } catch (e) {
        console.warn(e);
        actions.setErrors(processErrors(e));
      }
    },
  });

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    submitCount,
  } = formik;

  if (formik.status === 'success') {
    return <Redirect to="/signup/verify" push />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="form__cols">
        <Col sm={6} className="form__col">
          <InputRow
            label={t('firstName')}
            name="firstName"
            type="text"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={submitCount && errors.firstName && touched.firstName ? errors.firstName : null}
          />
        </Col>
        <Col className="d-sm-none mb-3" />
        <Col sm={6} className="form__col">
          <InputRow
            label={t('lastName')}
            name="lastName"
            type="text"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={submitCount && errors.lastName && touched.lastName ? errors.lastName : null}
          />
        </Col>
      </Row>
      <FormRow>
        <Row className="form__cols">
          <Col xs={12} sm={12} md={12} className='form__col'>
            <IntlTelInput
              onChangeNumber={handlePhoneNumberChange}
              onChangeCountry={setSelectedCountry}
              name="phoneNumber"
              label={t('mobileNumber')}
              type="tel"
              onBlur={handleBlur}
              onChangeValidity={valid => setIsValid(valid)}
              currLang={currLang}
            />
          </Col>
        </Row>
        {errors.phoneNumber && touched.phoneNumber ? (
          <FormError>{errors.phoneNumber}</FormError>
        ) : null}
      </FormRow>
      <FormRow type="submit">
        <Button
          htmlType="submit"
          size="large"
          type="primary"
          block
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {t('continue')}
        </Button>
      </FormRow>
    </Form>
  );
};

export default Personal;
