import React from 'react';
import { useTranslation } from 'react-i18next';
import IntegrationOption from './IntegrationOption';
import { ReactComponent as MagentoLogo } from './magento-sign.svg';
import { ReactComponent as ShopifyLogo } from './shopify-sign.svg';
import { ReactComponent as WooCommerceLogo } from './woocommerce.svg';

const IntegrationOptionsList = () => {
  const { t } = useTranslation();
  const list = [
    {
      key: 'shopify',
      logo: <ShopifyLogo />,
      title: t('connectShopify'),
      description: t('connectShopifyDesc'),
      actions: [
        {
          type: 'primary',
          size: 'small',
          to: '/settings/integrations/shopify',
          label: t('connect'),
        },
        {
          type: 'link',
          size: 'small',
          href: 'https://docs.paylaterapp.com/#shopify',
          label: t('documentation'),
        },
      ],
    },
    {
      key: 'magento',
      logo: <MagentoLogo />,
      title: t('installMagneto'),
      description: t('installMagnetoDesc'),
      actions: [
        // {
        //   type: 'primary',
        //   size: 'small',
        //   href: 'https://github.com/spotii-me/magento2',
        //   label: t('magnetoTwo'),
        // },
        {
          type: 'primary',
          size: 'small',
          href: 'https://docs.paylaterapp.com/#magento-2',
          label: t('documentation'),
        },
      ],
    },
    {
      key: 'woocommerce',
      logo: <WooCommerceLogo />,
      title: t('installWooCommerce'),
      description: t('installWooCommerceDesc'),
      actions: [
        // {
        //   type: 'primary',
        //   size: 'small',
        //   to: '/settings/integrations/shopify',
        //   label: 'Connect',
        // },
        {
          type: 'primary',
          size: 'small',
          href: 'https://docs.paylaterapp.com/#woocommerce',
          label: t('documentation'),
        },
      ],
    },
  ];

  return (
    <div className="integration-options-list">
      {list.map(item => (
        <IntegrationOption key={item.id} {...item} />
      ))}
    </div>
  );
};

export default IntegrationOptionsList;
