import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RecoveryNewPasswordForm from 'auth/RecoveryNewPasswordForm';
import Paper from 'auth/Paper';
import BlockHead from 'auth/BlockHead';
import Heading from 'spotii-ui/Heading';

export default function PasswordRecovery() {
  const { isVerified, isPopulated } = useSelector(state => state.recovery);

  if (!isVerified && !isPopulated) {
    return <Redirect to="/signin" />;
  }

  return (
    <div className="page-transition">
      <Paper size="tiny">
        <Row>
          <Col>
            <BlockHead>
              <Heading h1>Create a new password</Heading>
              <p>Set a new password for your PayLater account</p>
            </BlockHead>
            <RecoveryNewPasswordForm />
          </Col>
        </Row>
      </Paper>
    </div>
  );
}
